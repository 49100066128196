import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["source", "destination", "approx", "rate", "initial", "comission"]
  static values = {
    role: {type: String, default: "incoming"}
  }

  connect() {
    // console.log("Rate controller", this.roleValue)
    // this.convertSource();
    this.convertToApprox();
  }

  inverted() {
    if (!this.hasRateTarget) return false;
    let i = this.rateTarget.getAttribute("inverted") == "true"
    if (this.roleValue == "incoming") i = !i
    return i
  }

  correctedRate(){
    if (!this.hasRateTarget) return 1;
    let r = this.rateTarget.value
    if (this.inverted()) r = 1/r;
    return r;
  }

  convertDestination(event) {
    this.sourceTarget.value = (this.destinationTarget.value / this.correctedRate()).toFixed(2)
    this.convertToApprox(event);
    this.updateComission(event)
  }

  convertSource(event) {
    this.destinationTarget.value = (this.sourceTarget.value * this.correctedRate()).toFixed(2)
    this.convertToApprox(event);
    this.updateComission(event)
  }

  convertToApprox(event) {
    if (! this.hasApproxTarget) return;
    console.log("converting to approx value")
    let v = (this.destinationTarget.value / this.correctedRate()).toFixed(2)
    this.approxTargets.forEach((el) => {
      el.innerText = v;
      el.value = v;
    });

  }

  // Copies initial value to source
  // Used for payments usability
  copyInitial(event) {
    if (! this.hasSourceTarget) return;
    if (! this.hasInitialTarget) return;
    this.sourceTarget.value = this.initialTarget.value;
    this.convertSource(event)
    this.updateComission(event)
  }

  // Sets source amount based on comission value
  setComission(event) {
    if (! this.hasSourceTarget) return;
    if (! this.hasInitialTarget) return;
    if (! this.hasComissionTarget) return;

    let c = this.comissionTarget.value;
    if (this.roleValue == "incoming") c = -c;
    this.sourceTarget.value = (this.initialTarget.value - c).toFixed(2)

    this.convertSource(event)
  }

  updateComission(event){
    if (! this.hasComissionTarget) return;
    if (event.target == this.comissionTarget) return;
    let v = (this.initialTarget.value - this.sourceTarget.value)
    if (this.roleValue == "incoming") v = -v
    this.comissionTarget.value = v.toFixed(2)
  }

}

