import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {

  static targets = [ "toggler", "checkbox" ]

  connect() {
    this.recalculateToggler();

    document.addEventListener("turbo:morph", (event) => {
      this.connect(); // Reinitialize
    });
    
  }

  toggle(event) {
    console.log("targets:", this.checkboxTargets)
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = this.togglerTarget.checked
    });
  }

  checkbox(event) {
    this.recalculateToggler();
  }

  recalculateToggler(){
    if (!this.hasTogglerTarget) return;
    if (!this.hasCheckboxTarget) {
      this.togglerTarget.checked = false;
      this.togglerTarget.setAttribute("checked", false)
    } else {
      this.togglerTarget.checked = this.checkboxTargets.every(checkbox => checkbox.checked);
      this.togglerTarget.setAttribute("checked", true)
    }
  }

}

