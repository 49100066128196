import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["amount", "rate", "converted"]

  connect() {
    if (this.hasConvertedTarget && this.amountTarget.value && this.convertedTarget.value) {
      this.rateTarget.value = Math.round(this.amountTarget.value / this.convertedTarget.value * 100)/100
    }
  }

  convert() {
    if (this.amountTarget.value && this.rateTarget.value) {
      this.convertedTarget.value = Math.round(this.amountTarget.value/this.rateTarget.value*100)/100
    } else {
      this.convertedTarget.value = null
    }
    this.convertedTarget.dispatchEvent(new Event('input'))
  }

  changeRate(event) {
    if (event.inputType != "insertText") return;
    if (this.amountTarget.value && this.convertedTarget.value) {
      let v = Math.round(this.amountTarget.value/this.convertedTarget.value*100)/100
      this.rateTarget.value = v
    }
  }


}


