import { Controller } from "@hotwired/stimulus"
import {Tooltip} from "bootstrap"

export default class extends Controller {

  static targets = [ ]

  connect() {
    let t = new Tooltip(this.element, {html: true})
  }

}

