import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

// Connects to data-controller="toggle"
export default class extends Controller {

  static targets = [ "dateFrom", "dateTo" ]

  connect() {
    console.log("Dates initited")
  }

  prevWeek(event) {

    let dateFrom = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
    let dateTo = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');

    console.log("Filling dates", dateFrom, dateTo)

    this.fill(dateFrom, dateTo)

  }

  prevMonth(event) {

    let dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    let dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

    console.log("Filling dates", dateFrom, dateTo)

    this.fill(dateFrom, dateTo)

  }

  prevYear(event) {

    let dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    let dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');

    console.log("Filling dates", dateFrom, dateTo)

    this.fill(dateFrom, dateTo)

  }

  clear(event) {

    console.log("Clear dates")

    this.fill(null, null)

  }


  fill(dateFrom, dateTo) {
    this.dateFromTarget.setAttribute("data-flatpickr-date-value", dateFrom)
    this.dateToTarget.setAttribute("data-flatpickr-date-value", dateTo)
  }


}

