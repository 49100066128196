import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["rate", "date", "code", "bankCode"]

  connect() {
    if (this.hasRateTarget && !this.rateTarget.value) this.fetchRate()
  }

  fetchRate = async () => {

    if (!(this.hasDateTarget && this.hasCodeTarget && this.dateTarget.value && this.codeTarget.value)) {
      if (this.hasRateTarget) this.rateTarget.value = null;
      else return;
    }

    let date = moment().format("YYYY-MM-DD");
    if (this.hasDateTarget) date = this.dateTarget.value

    const url = "/currency_rates.json?by_date="+date
    const response = await fetch(url)
    const json = JSON.parse(await response.text())
    console.log("Currencies JSON:", json)
    console.log("Requested code:", this.codeTarget.textContent)
    let currency = json.find(obj => {
      return obj.code == this.codeTarget.textContent
    })
    if (currency) {
      console.log("Found rate", currency.rate)
      this.rateTargets.forEach(t => {
        console.log("kekovaz")
        t.value = currency.rate;
        t.dispatchEvent(new Event('input'));
      })
    }
    else this.rateTargets.forEach(t => t.value = null)
  }

  companyChange({detail: option}){
    console.log("CurrencyController: Company changed:", option.option)
    if (option.option) {
      this.codeTargets.forEach(t => t.textContent = option.option.dataset.currency)
      if (this.hasRateTarget) this.fetchRate()
    } else {
      this.codeTargets.forEach(t => t.textContent = "")
    }
  }

  bankChange({detail: option}){
    let bank = option.option
    if (! bank) return;
    console.log("CurrencyController: Bank changed:", bank)
    if (this.hasBankCodeTarget) this.bankCodeTarget.textContent = bank.dataset.currency
    else this.codeTarget.textContent = bank.dataset.currency
    //this.fetchRate()
  }

}


