import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["total", "item"]

  connect() {
    // this.total()
  }

  total() {

    if (this.itemTargets.find((el) => el.value == "")){
      // Any of values is null
      this.totalTarget.value = null;
    } else {

      let total = 1
      this.itemTargets.forEach((el) => total *= parseFloat(el.value || 0));
      this.totalTarget.value = total;
    }
  }

}
