import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["sum", "item"]

  connect() {
    this.sum()
  }

  sum() {
    let sum = 0
    this.itemTargets.forEach((el) => sum += parseFloat(el.value || 0));
    this.sumTarget.value = sum;
  }

}
