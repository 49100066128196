import { Controller } from "@hotwired/stimulus"
import * as XLSX from "xlsx";

export default class extends Controller {

  static values = {
    filename: String,
    table: String
  }
  static targets = [ "table" ]

  connect() {
  }

  download (event) {

    event.stopPropagation();
    event.preventDefault();

    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById(this.tableValue);

    for (let elm of table_elt.querySelectorAll("[data-raw]")) {
      if (elm.tagName == "TD") {
        elm.textContent = elm.dataset.raw;
      } else {
        let td = elm.parentElement.closest("td")
        if (td) {
          td.textContent = elm.dataset.raw;
        } else {
          console.log("TD", elm.dataset.raw)
          elm.parentElement.textContent = elm.dataset.raw;
        }
      }
    }
 
    // Uncollapsing all collapsible rows
    for (let elm of table_elt.querySelectorAll(".collapse")) {
      elm.classList.remove("collapse")
    }

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, this.filenameValue)
	} 


}
