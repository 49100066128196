import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["rows"]

  connect() {
  }

  add() {
    event.preventDefault();

    let r = this.rowsTarget.querySelector('.visually-hidden')
 
    console.log("Found hidden element", r)

    if (r) r.classList.remove("visually-hidden")

    return false;
  }


}


