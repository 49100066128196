import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload"
export default class extends Controller {

  static targets = ["progress", "files"]

  connect() {
    
  }

  upload(event) {

    this.element.requestSubmit();
    if (this.hasProgressTarget) {
      this.progressTarget.classList.remove("d-none")
    }

    return;

    // event.preventDefault();

    // var file = this.filesTarget.files[0];
    // // alert(file.name+" | "+file.size+" | "+file.type);
    // var formdata = new FormData();
    // formdata.append("file1", file);
    // var ajax = new XMLHttpRequest();
    // ajax.upload.addEventListener("progress", this.progressHandler, false);
    // ajax.addEventListener("load", this.completeHandler, false);
    // ajax.addEventListener("error", this.errorHandler, false);
    // ajax.addEventListener("abort", this.abortHandler, false);
    // ajax.open(this.element.getAttribute("method"), this.element.getAttribute("action")); 
    // ajax.send(formdata);



  }

  progressHandler(){

  }


  progressHandler(event) {
    // _("loaded_n_total").innerHTML = "Uploaded " + event.loaded + " bytes of " + event.total;
    // var percent = (event.loaded / event.total) * 100;
    // _("progressBar").value = Math.round(percent);
    // _("status").innerHTML = Math.round(percent) + "% uploaded... please wait";
  }
  
  completeHandler(event) {
    if (this.progressTarget) {
      this.progressTarget.classList.add("d-none")
    }
    // _("status").innerHTML = event.target.responseText;
    // _("progressBar").value = 0; //wil clear progress bar after successful upload
  }
  
  errorHandler(event) {
    // _("status").innerHTML = "Upload Failed";
  }
  
  abortHandler(event) {
    // _("status").innerHTML = "Upload Aborted";
  }

}
