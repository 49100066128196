import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["source", "part", "total"]

  connect() {
    this.count();
  }

  count() {

    console.log("Validate total count called")

    if (!(this.hasSourceTarget && this.hasPartTarget && this.hasTotalTarget)) {
      return;
    }

    let source = this.sourceTarget.value

    let totals = 0

    this.partTargets.forEach(element => {
      if (element.value) totals += parseFloat(element.value)
    });

    console.log("Validate total totals eq", totals)

    this.totalTarget.textContent = totals;

    if (source){
      if (totals != source) this.totalTarget.classList.add("invalid")
      else this.totalTarget.classList.remove("invalid")
    }

  }


}


