import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
  }
  static targets = [ ]

  connect() {
  }

  expand() {
    this.element.classList.remove("unexpanded")
    this.element.classList.add("expanded")
  }


}
