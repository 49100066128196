import { Controller } from "@hotwired/stimulus"
import { Previewer } from 'pagedjs';

// Connects to data-controller="toggle"
export default class extends Controller {

  static targets = [  ]

  connect() {
  }

  print() {
    console.log("Printing!")

    let paged = new Previewer();
    let flow = paged.preview().then((flow) => {
      console.log("Rendered", flow.total, "pages.");
      window.print();
    })    
  }

}

