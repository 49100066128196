import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  
  connect() {
    console.log("Toast connected")
    this.toast = new Toast(this.element)
    this.toast.show()
    this.element.addEventListener('hidden.bs.toast', () => {
      this.element.remove()
    })
  }

  disconnect() {
    console.log("disconnected")
    this.toast.hide()
  }

}
