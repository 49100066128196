import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {

  static targets = [ "results" ]

  connect() {
    this.search = debounce(this.search, 300)
  }

  search = () => {
    let formData = new FormData(this.element)
    let q = formData.get("q")
    console.log("SEARCH", q)

    if (q.length >= 3) {
      this.element.requestSubmit()
    } else {
      this.clean();
    }
  }

  clean() {
    console.log("Clearing search results")
    this.resultsTarget.innerHTML = ""
  }

  hide(event){
    if (event && (this.element.contains(event.target))) {
      return;
    }
    this.clean();
  }


}

const debounce = (fn, delay = 10) => {
  let timeoutId = null

  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(fn, delay)
  }
}


