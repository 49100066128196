import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bill"
export default class extends Controller {

  static targets = ["order", "cargo", "bank", "bankContainer"]

  connect() {
  }
  companyChange({detail: option}){
    if (option.option) {
      console.log("Company changed", option.option.dataset)

      let v = option.option.dataset.autocompleteValue

      if (option.option.dataset.type == "Customer") this.setOrderFilter(v)
      if (option.option.dataset.type == "Contractor") this.setCargoFilter(v)
 
      if (this.hasBankTarget) {
        this.loadBanks(v)
      }

    } else {
      console.log("Company cleared")
      this.setOrderFilter(null)
      this.setCargoFilter(null)
    }
  }

  setOrderFilter(company_id){
    if (this.hasOrderTarget) {
      console.log("Setting order filter")
      if (company_id) {
        this.orderTargets.forEach(c => c.dataset.autocompleteParamsValue = JSON.stringify({"q[customer_id_eq]": company_id}))
      } else {
        this.orderTargets.forEach(c => c.dataset.autocompleteParamsValue = JSON.stringify({}))
      }
    }
  }

  setCargoFilter(company_id){
    if (this.hasCargoTarget) {
      console.log("Setting cargo filter")
      if (company_id) {
        this.cargoTargets.forEach(c => c.dataset.autocompleteParamsValue = JSON.stringify({"q[broker_id_eq]": company_id}))
      } else {
        this.cargoTargets.forEach(c => c.dataset.autocompleteParamsValue = JSON.stringify({}))
      }
    }
  }

  loadBanks(company_id){
    fetch("/wallets?autocomplete=true&q[company_id_eq]="+company_id)
      .then(response => response.text())
      .then(html => {
        if (html.includes("autocomplete_no_options_found")) {
          this.bankContainerTarget.classList.add("d-none")
        } else {
          this.bankContainerTarget.classList.remove("d-none")
        }
        let a = this.application.getControllerForElementAndIdentifier(this.bankTarget, 'autocomplete')
        a.replaceResults(html)
        //this.element.innerHTML = html
      })
  }

}
