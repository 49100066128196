import { Controller } from "@hotwired/stimulus"
 
// Connects to data-controller="toggle"
export default class extends Controller {

  static targets = [ "button" ]

  connect() {
    console.log("Autosubmit connected...")
  }

  refresh(event) {
    console.log("Refreshing form...")
    let at = this.element.querySelector("input[name=authenticity_token]")
    if (at) at.disabled = true
    if (event.params && event.params.action) {
      console.log("Sending action", event.params.action)
      this.buttonTarget.value = event.params.action
    }
    this.buttonTarget.click()
    this.buttonTarget.value = null
    if (at) at.disabled = false
  }

}

