import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency"
export default class extends Controller {

  static targets = ["origin", "exchange", "destination"]

  connect() {
  }

  select(event) {
    event.preventDefault();

    this.originTarget.value = event.target.dataset.originId;
    this.exchangeTarget.value = event.target.dataset.exchangeId;
    this.destinationTarget.value = event.target.dataset.destinationId;

    console.log(event.target.dataset)
  }


}


