import { Controller } from "@hotwired/stimulus"
import Chartkick from "chartkick";

export default class extends Controller {
    static targets = [];
    static values = {
        type: String,
        data: Array, 
        options: Object
    };

    chart = null;

    connect() {
        console.log("Chart connect")
        this.chart = new Chartkick[this.typeValue](
            this.element.id,
            this.dataValue,
            this.optionsValue
        );

        // Chartkick fix print size and crop
        window.addEventListener('beforeprint', () => {
                console.log("Yoy", this.chart.chart)
            // Chart.instances[id].resize();
                this.chart.chart.resize(800,300)
        });
        

    }

    disconnect() {

    }
}